var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "place-info" },
    [
      _c("div", { staticClass: "header" }, [
        _c(
          "button",
          { staticClass: "back ripple", on: { click: _vm.onClickBack } },
          [
            _c("i", { staticClass: "material-icons-round" }, [
              _vm._v("arrow_back"),
            ]),
          ]
        ),
        _c("div", { staticClass: "title" }, [_vm._v(_vm._s(_vm.title))]),
        !_vm.isDeletedStatus && !_vm.isEmptyPlaceDetail && !_vm.isRejectedStatus
          ? _c(
              "button",
              {
                ref: "toggle-action-list",
                staticClass: "toggle-action-list ripple",
                on: { click: _vm.onClickAction },
              },
              [
                _c("i", { staticClass: "material-icons-round" }, [
                  _vm._v("more_vert"),
                ]),
              ]
            )
          : _vm._e(),
      ]),
      _vm.isShowActionList && !_vm.isLoading
        ? _c("div", { ref: "action-list", staticClass: "action-list" }, [
            _c(
              "button",
              { staticClass: "action ripple", on: { click: _vm.onClickShare } },
              [
                _c("i", { staticClass: "material-icons-round" }, [
                  _vm._v("share"),
                ]),
                _c("span", [_vm._v(_vm._s(_vm.$t("placeInfo.action.share")))]),
              ]
            ),
            _c(
              "button",
              { staticClass: "action ripple", on: { click: _vm.onClickMap } },
              [
                _c("i", { staticClass: "material-icons-round" }, [
                  _vm._v("map"),
                ]),
                _c("span", [_vm._v(_vm._s(_vm.$t("placeInfo.action.map")))]),
              ]
            ),
            _c(
              "button",
              {
                staticClass: "action ripple",
                on: { click: _vm.onClickDirection },
              },
              [
                _c("i", { staticClass: "material-icons-round" }, [
                  _vm._v("navigation"),
                ]),
                _c("span", [
                  _vm._v(_vm._s(_vm.$t("placeInfo.action.navigation"))),
                ]),
              ]
            ),
            !_vm.isMarkFav
              ? _c(
                  "button",
                  {
                    staticClass: "action ripple",
                    on: { click: _vm.onClickFav },
                  },
                  [
                    _c("i", { staticClass: "material-icons-round" }, [
                      _vm._v("star_border"),
                    ]),
                    _c("span", [
                      _vm._v(_vm._s(_vm.$t("placeInfo.action.fav"))),
                    ]),
                  ]
                )
              : _vm._e(),
            _vm.isAuthenticated && _vm.isMarkFav
              ? _c(
                  "button",
                  {
                    staticClass: "action ripple",
                    on: { click: _vm.onClickUnfav },
                  },
                  [
                    _c("i", { staticClass: "material-icons-round" }, [
                      _vm._v("star"),
                    ]),
                    _c("span", [
                      _vm._v(_vm._s(_vm.$t("placeInfo.action.unFav"))),
                    ]),
                  ]
                )
              : _vm._e(),
            _vm.canEdit
              ? _c(
                  "button",
                  {
                    staticClass: "action ripple",
                    on: { click: _vm.onClickEdit },
                  },
                  [
                    _c("i", { staticClass: "material-icons-round" }, [
                      _vm._v("edit"),
                    ]),
                    _c("span", [
                      _vm._v(_vm._s(_vm.$t("placeInfo.action.edit"))),
                    ]),
                  ]
                )
              : _vm._e(),
            _vm.canDelete
              ? _c(
                  "button",
                  {
                    staticClass: "action ripple",
                    on: { click: _vm.onClickDelete },
                  },
                  [
                    _c("i", { staticClass: "material-icons-round" }, [
                      _vm._v("delete"),
                    ]),
                    _c("span", [
                      _vm._v(_vm._s(_vm.$t("placeInfo.action.delete"))),
                    ]),
                  ]
                )
              : _vm._e(),
          ])
        : _vm._e(),
      _vm.isLoading
        ? _c("div", { staticClass: "loading-overlay" }, [
            _c("img", { attrs: { src: require("@/assets/img/loading.gif") } }),
          ])
        : _vm._e(),
      !_vm.isLoading
        ? _c("div", { ref: "body", staticClass: "body" }, [
            _vm.mapSnippetWidth > 0 && !_vm.isEmptyPlaceDetail
              ? _c("div", {
                  staticClass: "map-snippet ripple",
                  style: {
                    width: _vm.mapSnippetWidth + "px",
                    height: _vm.mapSnippetHeight + "px",
                    backgroundImage: "url('" + _vm.mapSnippetUrl + "')",
                  },
                  on: { click: _vm.onClickMap },
                })
              : _vm._e(),
            _vm.isNewStatus
              ? _c("div", { staticClass: "new-status notice" }, [
                  _vm._v("! " + _vm._s(_vm.$t("placeInfo.newStatus"))),
                ])
              : _vm._e(),
            _vm.isObsoletedStatus
              ? _c("div", { staticClass: "obsoleted-status notice" }, [
                  _vm._v("! " + _vm._s(_vm.$t("placeInfo.obsoletedStatus"))),
                ])
              : _vm._e(),
            _vm.isDeletedStatus || _vm.isEmptyPlaceDetail
              ? _c("div", { staticClass: "deleted-status notice" }, [
                  _vm._v("! " + _vm._s(_vm.$t("placeInfo.deletedStatus"))),
                ])
              : _vm._e(),
            _vm.isRejectedStatus
              ? _c("div", { staticClass: "rejected-status notice" }, [
                  _vm._v("! " + _vm._s(_vm.$t("placeInfo.rejectedStatus"))),
                ])
              : _vm._e(),
            !_vm.isEmptyPlaceDetail
              ? _c("div", { staticClass: "place-name" }, [
                  _vm._v(_vm._s(_vm.placeName)),
                ])
              : _vm._e(),
            !_vm.isEmptyPlaceDetail
              ? _c("div", { staticClass: "sub-place-name" }, [
                  _vm._v(_vm._s(_vm.subPlaceName)),
                ])
              : _vm._e(),
            !_vm.isEmptyPlaceDetail
              ? _c("div", { staticClass: "promptpai" }, [
                  _vm.isPromptPai
                    ? _c("iframe", {
                        staticStyle: {
                          border: "none",
                          overflow: "hidden",
                          margin: "6px 0px 0px 0px",
                        },
                        attrs: {
                          src: _vm.promptPaiIframe,
                          width: "119",
                          height: "26",
                          scrolling: "no",
                          frameborder: "0",
                          allowTransparency: "true",
                          allow: "encrypted-media",
                        },
                      })
                    : _vm._e(),
                ])
              : _vm._e(),
            _vm.placeCategoryLabel
              ? _c("div", { staticClass: "field" }, [
                  _c(
                    "div",
                    {
                      staticClass: "field-icon",
                      on: {
                        touchstart: function ($event) {
                          return _vm.onTouchstartIcon($event)
                        },
                      },
                    },
                    [
                      _c("i", { staticClass: "material-icons-round" }, [
                        _vm._v("category"),
                      ]),
                      _c("label", [
                        _vm._v(_vm._s(_vm.$t("placeInfo.category"))),
                      ]),
                    ]
                  ),
                  _c("div", { staticClass: "content" }, [
                    _c("span", { staticClass: "place-category-icon" }, [
                      _c("img", { attrs: { src: _vm.placeCategoryIconSrc } }),
                    ]),
                    _c("span", [_vm._v(_vm._s(_vm.placeCategoryLabel))]),
                  ]),
                ])
              : _vm._e(),
            _vm.connectorList.length > 0
              ? _c("div", { staticClass: "field connector-list" }, [
                  _c(
                    "div",
                    {
                      staticClass: "field-icon",
                      on: {
                        touchstart: function ($event) {
                          return _vm.onTouchstartIcon($event)
                        },
                      },
                    },
                    [
                      _c("i", { staticClass: "material-icons-round" }, [
                        _vm._v("electrical_services"),
                      ]),
                      _c("label", [
                        _vm._v(_vm._s(_vm.$t("placeInfo.connector"))),
                      ]),
                    ]
                  ),
                  _c("div", { staticClass: "content" }, [
                    _c(
                      "ul",
                      _vm._l(_vm.connectorList, function (connector) {
                        return _c(
                          "div",
                          { key: "connector-" + connector.connector_type_id },
                          [
                            _c("span", { staticClass: "total-available" }, [
                              _vm._v(_vm._s(connector.total_available)),
                            ]),
                            _vm._v(
                              " " +
                                _vm._s(connector.charger_type_name) +
                                " " +
                                _vm._s(connector.connector_type_name) +
                                " " +
                                _vm._s(connector.discharge_electricity) +
                                " "
                            ),
                          ]
                        )
                      }),
                      0
                    ),
                  ]),
                ])
              : _vm._e(),
            _vm.address
              ? _c("div", { staticClass: "field" }, [
                  _c(
                    "div",
                    {
                      staticClass: "field-icon",
                      on: {
                        touchstart: function ($event) {
                          return _vm.onTouchstartIcon($event)
                        },
                      },
                    },
                    [
                      _c("i", { staticClass: "material-icons-round" }, [
                        _vm._v("place"),
                      ]),
                      _c("label", [
                        _vm._v(_vm._s(_vm.$t("placeInfo.address"))),
                      ]),
                    ]
                  ),
                  _c("div", { staticClass: "content" }, [
                    _vm._v(
                      " " +
                        _vm._s(_vm.address) +
                        " " +
                        _vm._s(
                          _vm.address_detail
                            ? " (" + _vm.address_detail + ")"
                            : ""
                        ) +
                        " "
                    ),
                  ]),
                ])
              : _vm._e(),
            _vm.parentPlaceName
              ? _c("div", { staticClass: "field" }, [
                  _c(
                    "div",
                    {
                      staticClass: "field-icon",
                      on: {
                        touchstart: function ($event) {
                          return _vm.onTouchstartIcon($event)
                        },
                      },
                    },
                    [
                      _c("i", { staticClass: "material-icons-round" }, [
                        _vm._v("home_work"),
                      ]),
                      _c("label", [
                        _vm._v(_vm._s(_vm.$t("placeInfo.parentPlace"))),
                      ]),
                    ]
                  ),
                  _c("div", { staticClass: "content" }, [
                    _c("a", { on: { click: _vm.gotoParentPlaceInfo } }, [
                      _vm._v(_vm._s(_vm.parentPlaceName)),
                    ]),
                  ]),
                ])
              : _vm._e(),
            _vm.busList.length > 0
              ? _c("div", { staticClass: "field" }, [
                  _c(
                    "div",
                    {
                      staticClass: "field-icon",
                      on: {
                        touchstart: function ($event) {
                          return _vm.onTouchstartIcon($event)
                        },
                      },
                    },
                    [
                      _c("i", { staticClass: "material-icons-round" }, [
                        _vm._v("directions_bus"),
                      ]),
                      _c("label", [_vm._v(_vm._s(_vm.$t("placeInfo.bus")))]),
                    ]
                  ),
                  _c("div", { staticClass: "content" }, [
                    _c(
                      "div",
                      { staticClass: "bus-list" },
                      _vm._l(_vm.busList, function (bus, i) {
                        return _c(
                          "span",
                          { key: "bus-" + i, staticClass: "bus ripple" },
                          [_vm._v(_vm._s(bus.display))]
                        )
                      }),
                      0
                    ),
                  ]),
                ])
              : _vm._e(),
            _vm.bookingcomUrl
              ? _c("div", { staticClass: "field" }, [
                  _c(
                    "div",
                    {
                      staticClass: "field-icon",
                      on: {
                        touchstart: function ($event) {
                          return _vm.onTouchstartIcon($event)
                        },
                      },
                    },
                    [
                      _c("i", { staticClass: "material-icons-round" }, [
                        _vm._v("hotel"),
                      ]),
                      _c("label", [
                        _vm._v(_vm._s(_vm.$t("placeInfo.booking"))),
                      ]),
                    ]
                  ),
                  _c("div", { staticClass: "content" }, [
                    _c(
                      "a",
                      {
                        on: {
                          click: function ($event) {
                            return _vm.onClickUrl(_vm.bookingcomUrl)
                          },
                        },
                      },
                      [_vm._v("Booking.com")]
                    ),
                  ]),
                ])
              : _vm._e(),
            _vm.workingHoursList.length > 0
              ? _c("div", { staticClass: "field" }, [
                  _c(
                    "div",
                    {
                      staticClass: "field-icon",
                      on: {
                        touchstart: function ($event) {
                          return _vm.onTouchstartIcon($event)
                        },
                      },
                    },
                    [
                      _c("i", { staticClass: "material-icons-round" }, [
                        _vm._v("schedule"),
                      ]),
                      _c("label", [
                        _vm._v(_vm._s(_vm.$t("placeInfo.schedule"))),
                      ]),
                    ]
                  ),
                  _c("div", { staticClass: "content" }, [
                    _c(
                      "div",
                      { staticClass: "col" },
                      [
                        _vm._l(
                          _vm.workingHoursList,
                          function (workingHours, i) {
                            return _c(
                              "div",
                              {
                                key: "working-hours-" + i,
                                staticClass: "working-hours row",
                              },
                              [
                                _c("div", { staticClass: "day" }, [
                                  _vm._v(
                                    _vm._s(_vm.$t("day." + workingHours.day))
                                  ),
                                ]),
                                _c("div", { staticClass: "start" }, [
                                  _vm._v(_vm._s(workingHours.start)),
                                ]),
                                _c("div", { staticClass: "to" }, [_vm._v("-")]),
                                _c("div", { staticClass: "end" }, [
                                  _vm._v(_vm._s(workingHours.end)),
                                ]),
                              ]
                            )
                          }
                        ),
                        _c("div", { staticClass: "remark" }, [
                          _vm._v(_vm._s(_vm.workingHoursRemark)),
                        ]),
                      ],
                      2
                    ),
                  ]),
                ])
              : _vm._e(),
            _vm.mobileList.length > 0
              ? _c("div", { staticClass: "field" }, [
                  _c(
                    "div",
                    {
                      staticClass: "field-icon",
                      on: {
                        touchstart: function ($event) {
                          return _vm.onTouchstartIcon($event)
                        },
                      },
                    },
                    [
                      _c("i", { staticClass: "material-icons-round" }, [
                        _vm._v("phone_iphone"),
                      ]),
                      _c("label", [_vm._v(_vm._s(_vm.$t("placeInfo.mobile")))]),
                    ]
                  ),
                  _c("div", { staticClass: "content" }, [
                    _c(
                      "div",
                      { staticClass: "col" },
                      _vm._l(_vm.mobileList, function (mobile, i) {
                        return _c("div", { key: "mobile-" + i }, [
                          _c(
                            "a",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.onClickUrl(mobile.href)
                                },
                              },
                            },
                            [_vm._v(_vm._s(mobile.display))]
                          ),
                          mobile.remark
                            ? _c("div", { staticClass: "contact-remark" }, [
                                _vm._v(_vm._s(mobile.remark)),
                              ])
                            : _vm._e(),
                        ])
                      }),
                      0
                    ),
                  ]),
                ])
              : _vm._e(),
            _vm.telList.length > 0
              ? _c("div", { staticClass: "field" }, [
                  _c(
                    "div",
                    {
                      staticClass: "field-icon",
                      on: {
                        touchstart: function ($event) {
                          return _vm.onTouchstartIcon($event)
                        },
                      },
                    },
                    [
                      _c("i", { staticClass: "material-icons-round" }, [
                        _vm._v("phone"),
                      ]),
                      _c("label", [_vm._v(_vm._s(_vm.$t("placeInfo.phone")))]),
                    ]
                  ),
                  _c("div", { staticClass: "content" }, [
                    _c(
                      "div",
                      { staticClass: "col" },
                      _vm._l(_vm.telList, function (tel, i) {
                        return _c("div", { key: "tel-" + i }, [
                          _c(
                            "a",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.onClickUrl(tel.href)
                                },
                              },
                            },
                            [_vm._v(_vm._s(tel.display))]
                          ),
                          tel.remark
                            ? _c("div", { staticClass: "contact-remark" }, [
                                _vm._v(_vm._s(tel.remark)),
                              ])
                            : _vm._e(),
                        ])
                      }),
                      0
                    ),
                  ]),
                ])
              : _vm._e(),
            _vm.faxList.length > 0
              ? _c("div", { staticClass: "field" }, [
                  _c(
                    "div",
                    {
                      staticClass: "field-icon",
                      on: {
                        touchstart: function ($event) {
                          return _vm.onTouchstartIcon($event)
                        },
                      },
                    },
                    [
                      _c("i", { staticClass: "material-icons-round" }, [
                        _vm._v("print"),
                      ]),
                      _c("label", [_vm._v(_vm._s(_vm.$t("placeInfo.fax")))]),
                    ]
                  ),
                  _c("div", { staticClass: "content" }, [
                    _c(
                      "div",
                      { staticClass: "col" },
                      _vm._l(_vm.faxList, function (fax, i) {
                        return _c("div", { key: "fax-" + i }, [
                          _c(
                            "a",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.onClickUrl(fax.href)
                                },
                              },
                            },
                            [_vm._v(_vm._s(fax.display))]
                          ),
                          fax.remark
                            ? _c("div", { staticClass: "contact-remark" }, [
                                _vm._v(_vm._s(fax.remark)),
                              ])
                            : _vm._e(),
                        ])
                      }),
                      0
                    ),
                  ]),
                ])
              : _vm._e(),
            _vm.specialTelList.length > 0
              ? _c("div", { staticClass: "field" }, [
                  _c(
                    "div",
                    {
                      staticClass: "field-icon",
                      on: {
                        touchstart: function ($event) {
                          return _vm.onTouchstartIcon($event)
                        },
                      },
                    },
                    [
                      _c("i", { staticClass: "material-icons-round" }, [
                        _vm._v("support_agent"),
                      ]),
                      _c("label", [
                        _vm._v(_vm._s(_vm.$t("placeInfo.specialNumber"))),
                      ]),
                    ]
                  ),
                  _c("div", { staticClass: "content" }, [
                    _c(
                      "div",
                      { staticClass: "col" },
                      _vm._l(_vm.specialTelList, function (tel, i) {
                        return _c("div", { key: "tel-" + i }, [
                          _c(
                            "a",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.onClickUrl(tel.href)
                                },
                              },
                            },
                            [_vm._v(_vm._s(tel.display))]
                          ),
                          tel.remark
                            ? _c("div", { staticClass: "contact-remark" }, [
                                _vm._v(_vm._s(tel.remark)),
                              ])
                            : _vm._e(),
                        ])
                      }),
                      0
                    ),
                  ]),
                ])
              : _vm._e(),
            _vm.lineList.length > 0
              ? _c("div", { staticClass: "field" }, [
                  _c(
                    "div",
                    {
                      staticClass: "field-icon",
                      on: {
                        touchstart: function ($event) {
                          return _vm.onTouchstartIcon($event)
                        },
                      },
                    },
                    [
                      _c("div", {
                        staticClass: "image",
                        style: {
                          backgroundImage: "url('" + _vm.lineLogoUrl + "')",
                        },
                      }),
                      _c("label", [_vm._v(_vm._s(_vm.$t("placeInfo.line")))]),
                    ]
                  ),
                  _c("div", { staticClass: "content" }, [
                    _c(
                      "div",
                      { staticClass: "col" },
                      _vm._l(_vm.lineList, function (line, i) {
                        return _c("div", { key: "line-" + i }, [
                          line.id
                            ? _c("span", [_vm._v(_vm._s(line.id))])
                            : _c(
                                "a",
                                {
                                  on: {
                                    click: function ($event) {
                                      return _vm.onClickUrl(
                                        "https://line.me/ti/p/" + line.link
                                      )
                                    },
                                  },
                                },
                                [_vm._v(_vm._s(line.link))]
                              ),
                          line.remark
                            ? _c("div", { staticClass: "contact-remark" }, [
                                _vm._v(_vm._s(line.remark)),
                              ])
                            : _vm._e(),
                        ])
                      }),
                      0
                    ),
                  ]),
                ])
              : _vm._e(),
            _vm.website
              ? _c("div", { staticClass: "field" }, [
                  _c(
                    "div",
                    {
                      staticClass: "field-icon",
                      on: {
                        touchstart: function ($event) {
                          return _vm.onTouchstartIcon($event)
                        },
                      },
                    },
                    [
                      _c("i", { staticClass: "material-icons-round" }, [
                        _vm._v("public"),
                      ]),
                      _c("label", [
                        _vm._v(_vm._s(_vm.$t("placeInfo.website"))),
                      ]),
                    ]
                  ),
                  _c("div", { staticClass: "content" }, [
                    _c(
                      "a",
                      {
                        on: {
                          click: function ($event) {
                            return _vm.onClickUrl(_vm.website)
                          },
                        },
                      },
                      [_vm._v(_vm._s(_vm.website))]
                    ),
                  ]),
                ])
              : _vm._e(),
            _vm.placeTagList.length > 0
              ? _c("div", { staticClass: "field" }, [
                  _c(
                    "div",
                    {
                      staticClass: "field-icon",
                      on: {
                        touchstart: function ($event) {
                          return _vm.onTouchstartIcon($event)
                        },
                      },
                    },
                    [
                      _c("i", { staticClass: "material-icons-round" }, [
                        _vm._v("local_offer"),
                      ]),
                      _c("label", [_vm._v(_vm._s(_vm.$t("placeInfo.tag")))]),
                    ]
                  ),
                  _c("div", { staticClass: "content" }, [
                    _c(
                      "div",
                      { staticClass: "tag-list" },
                      _vm._l(_vm.placeTagList, function (tag, i) {
                        return _c(
                          "span",
                          {
                            key: "tag-" + i,
                            staticClass: "tag ripple",
                            on: {
                              click: function ($event) {
                                return _vm.onClickTag(tag)
                              },
                            },
                          },
                          [_vm._v(_vm._s(tag))]
                        )
                      }),
                      0
                    ),
                  ]),
                ])
              : _vm._e(),
            _vm.descriptionHtml
              ? _c("div", { staticClass: "field full-description" }, [
                  _c(
                    "div",
                    {
                      staticClass: "field-icon",
                      on: {
                        touchstart: function ($event) {
                          return _vm.onTouchstartIcon($event)
                        },
                      },
                    },
                    [
                      _c("i", { staticClass: "material-icons-round" }, [
                        _vm._v("description"),
                      ]),
                      _c("label", [
                        _vm._v(_vm._s(_vm.$t("placeInfo.descriptionHtml"))),
                      ]),
                    ]
                  ),
                  _c("div", { staticClass: "content" }, [
                    _c("div", {
                      domProps: { innerHTML: _vm._s(_vm.descriptionHtml) },
                    }),
                  ]),
                ])
              : _vm._e(),
            _vm.imageList.length > 0
              ? _c("div", { staticClass: "col" }, [
                  _c("div", { staticClass: "image-label row" }, [
                    _c("i", { staticClass: "material-icons-round" }, [
                      _vm._v("image"),
                    ]),
                    _vm._v(" " + _vm._s(_vm.$t("placeInfo.imageLabel")) + " "),
                  ]),
                  _c(
                    "div",
                    { staticClass: "image-list row" },
                    _vm._l(_vm.imageList, function (image, i) {
                      return _c("ImageWidget", {
                        key: "image-" + i,
                        staticClass: "image",
                        attrs: {
                          image: image,
                          index: i,
                          width: _vm.imageWidth,
                          height: _vm.imageHeight,
                          canView: true,
                          showPrevButton: i > 0,
                          showNextButton: i < _vm.imageList.length - 1,
                        },
                        on: {
                          clickPrev: function ($event) {
                            return _vm.onClickPrevImage(i)
                          },
                          clickNext: function ($event) {
                            return _vm.onClickNextImage(i)
                          },
                        },
                      })
                    }),
                    1
                  ),
                ])
              : _vm._e(),
            _c("div", {
              staticClass: "date",
              domProps: { innerHTML: _vm._s(_vm.date) },
            }),
          ])
        : _vm._e(),
      _vm.isShowSharePlaceWidget
        ? _c("SharePlaceWidget", {
            attrs: {
              poiId: _vm.poiId,
              lat: _vm.lat,
              lon: _vm.lon,
              isShareInfoPage: true,
            },
            on: {
              close: function ($event) {
                _vm.isShowSharePlaceWidget = false
              },
            },
          })
        : _vm._e(),
      _vm.isUpdatingFav || _vm.isDeleting
        ? _c("div", { staticClass: "loading-overlay" }, [
            _c("img", { attrs: { src: require("@/assets/img/loading.gif") } }),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }