var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "info-place" },
    [
      _c("PlaceInfo", {
        attrs: { poiId: _vm.$route.params.poiId, userData: _vm.userData },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }